import { render, staticRenderFns } from "./Amortizations.vue?vue&type=template&id=29d39f78&"
import script from "./Amortizations.vue?vue&type=script&lang=ts&"
export * from "./Amortizations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29d39f78')) {
      api.createRecord('29d39f78', component.options)
    } else {
      api.reload('29d39f78', component.options)
    }
    module.hot.accept("./Amortizations.vue?vue&type=template&id=29d39f78&", function () {
      api.rerender('29d39f78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account-payables/amortization/Amortizations.vue"
export default component.exports