


































import { CloseAmortizationFormState } from "@/models/interface/amortization";
import { FormUtils } from "@/utils/FormUtils";
import { FormModel } from "ant-design-vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component({})
export default class CloseAmortizationModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  visible!: boolean;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    note: FormUtils.mandatory(this.$t("lbl_validation_required_error")),
  };

  loading = false;
  form: CloseAmortizationFormState = {
    note: "",
  };

  handleCancel(): void {
    this.$emit("close");
  }

  handleSubmit(): void {
    this.formModel.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      this.$emit("submit", this.form.note);
    });
  }
}
