var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("common.close-text", {
          text: _vm.$t("lbl_amortization")
        }),
        visible: _vm.visible,
        width: "50%"
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.$t("lbl_confirm_close_amortization")))]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: { model: _vm.form, rules: _vm.formRules, layout: "vertical" }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "note" } },
            [
              _c("a-textarea", {
                attrs: {
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_note")
                  })
                },
                model: {
                  value: _vm.form.note,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }