var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.close-text", {
          text: _vm.$t("lbl_amortization")
        }),
        loading: _vm.loading.getDetail
      }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            model: _vm.formData,
            rules: _vm.formRules,
            "data-testid": "close-amortization-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_amortization_number"),
                        "data-testid": "close-amortization-amortization-number"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.amortizationDetail.amortizationNumber) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_transaction_type"),
                        "data-testid": "close-amortization-transaction-type"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.amortizationDetail.transactionType) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.unitCodeOptions,
                          "label-in-value": "",
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_unit_code")
                          }),
                          "allow-clear": "",
                          "data-testid": "close-amortization-unit-code"
                        },
                        on: { change: _vm.handleUnitCodeChange },
                        model: {
                          value: _vm.formData.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "unitCode", $$v)
                          },
                          expression: "formData.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_closing_date"),
                        prop: "closingDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.beforeStartPeriod,
                          "allow-clear": "",
                          "data-testid": "close-amortization-closing-date"
                        },
                        model: {
                          value: _vm.formData.closingDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "closingDate", $$v)
                          },
                          expression: "formData.closingDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_description"),
                        prop: "description"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.formData.description }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "data-testid": "close-amortization-description"
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_leasing_number"),
                        "data-testid": "close-amortization-leasing-number"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.amortizationDetail.leasingNumber) + " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_leasing_contract_number"),
                        "data-testid":
                          "close-amortization-leasing-contract-number"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.amortizationDetail.leasingContractNumber) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      "data-source": _vm.units,
                      columns: _vm.columns,
                      scroll: { x: 1200 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "close-amortization-table"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid": "close-amortization-btn-back"
                          },
                          on: { click: _vm.handleBack }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                      ),
                      _vm.$can("close", "amortization")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.close,
                                "data-testid": "close-amortization-btn-close"
                              },
                              on: { click: _vm.handleCloseAmortization }
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_close")))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CloseAmortizationModal", {
        attrs: { visible: _vm.modal.visible },
        on: {
          close: function($event) {
            return _vm.modal.close()
          },
          submit: _vm.closeAmortization
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }